.redirectDescription {
  color: black;
}

.Checkout {
  ._loading_overlay_overlay {
    background-color: transparent;
  }
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  .cardForm {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;

    .creditCardForm {
      width: 100%;
      // margin-left: auto;
      // margin-right: auto;
      max-width: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;

      input,
      select {
        height: 40px;
        padding-left: 10px;
        font-family: "margem";
        border-radius: 5px;
        outline: none;
        border: 1px solid black;
      }

      input {
        width: 100%;
      }

      .creditCardField {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
  }

  .right {
    display: flex;
    justify-items: flex-end;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    span {
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 990px) {
    flex-direction: column;
    padding: 0px;
    .animatedCreditCard {
      display: none;
      // margin: auto !important;
    }

    .right {
      align-items: center;
      justify-content: center;
    }
  }
}

.modalBodyCheckout {
  @media screen and (max-width: 990px) {
    padding: 0px;
    form {
      max-width: 220px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.fixed-right-botton {
  position: fixed;
  padding: 5px;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  background-color: #FFF;
}
