.sc-launcher {
  cursor: pointer;
}
.rce-mbox-right {
  background-color: var(--newbackground-color);
  color: white;
  .rce-mbox-time {
    color: white;
  }
  .rce-mbox-text a {
    color: white;
  }
}

.rce-mbox-right-notch {
  fill: var(--newbackground-color);
}

.sc-chat-window.opened {
  z-index: 2000;
}

.rce-mbox-text p {
  margin: 0px !important;
}

.rce-mbox-file > button {
  max-width: 280px !important;
}

.rce-mbox-photo {
  .rce-mbox-text {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sc-user-input--text {
  cursor: text !important;
}

.sc-header--img {
  width: 70px;
}

.rce-mbox-forward {
  color: rgb(8, 8, 8)
}
