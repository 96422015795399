.left-sidebar {
  height: 100vh;
  position: absolute;
  width: 15rem;
  left: 0rem;
  max-width: 15rem;
  background-color: white;
  left: -15rem;
  box-shadow: #0000000d -4px 0px 20px inset !important;
  background-color: rgb(247, 247, 247) !important;
  z-index: 5;
  transition: all 0.5s ease;
}

.left-sidebar-transition {
  transition: all 0.5s ease;
  left: 0rem;
}
