// General utilities

// Overflow helpers
.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-visible {
    overflow: visible;
  }
  &-scroll {
    overflow: scroll;
  }
  &-auto {
    overflow: auto;
  }
}

// Remove shadows
.no-shadow {
  box-shadow: none !important;
}
.imagesDownloadProject {
  border-color: var(--newbackground-color) !important;
}

.clickable:hover {
  cursor: pointer;
}

.unclickable:hover {
  cursor: auto !important;
}

.notification-icon {
  margin-left: auto;
  @media screen and (max-width: "780px") {
    margin-left: 0px;
  }
}

.manDiv-mobile {
  @media screen and (max-width: "960px") {
    margin-top: 0px !important;
  }
}
.title-mobile-IDP {
  @media screen and (max-width: "960px") {
    margin-top: 10px !important;
  }
}
.ril__caption {
  justify-content: center;
  align-items: center;
}

.ril__captionContent {
  max-height: 150px;
  width: 100%;
  padding: 20px !important;
  word-break: break-all;
  overflow: auto;
  position: relative;
}

.detailImgProject {
  width: 170px;
  p {
    padding-left: 5px;
  }
  word-break: break-all;

  @media screen and (max-width: "960px") {
    margin-left: 0px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.Final-project-card-mobile {
  padding-right: 5%;
  padding-left: 5%;
  .col-md-12 {
    padding: 0px;
  }

  @media screen and (max-width: "960px") {
    padding-right: 10.5%;
    padding-left: 10.5%;
  }
}
.productlist-mobile {
  padding-bottom: 1rem;

  @media screen and (max-width: "960px") {
    padding-right: 3rem;
    padding-left: 3rem;
    .card-body {
      margin-top: 1rem;
    }
  }
}

.banner-guide {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media screen and (max-width: "960px") {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}
.exemple-dash {
  padding: 1.875rem !important;
  .col-md-12 {
    padding: 0px;
  }
  @media screen and (max-width: "992px") {
    padding: 1.4rem !important;
    .card {
      padding: 0px !important;

      .card-body {
        padding: 0px 35px !important;
        margin: 0px !important;
      }
    }
  }

  .exemple-steps {
    @media screen and (max-width: "992px") {
      .col-md-6,
      .col-md-8 {
        margin: 5px;
      }
    }
  }
}
.mobile-review {
  @media screen and (max-width: "960px") {
    .col {
      padding: 0px;
      .card-body {
        padding: 0px !important;
        .card {
          padding: 0px !important;
          .card-body {
            padding: 0px 35px !important;
            margin: 0px !important;
          }
        }
      }
    }
    .mobile-bottom-buttons button {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
        margin-top: 0px;
      }
      &:not(:first-child) {
        margin-left: 0px !important;
      }

      width: 100%;
    }
  }
}

.finishBuyButton {
  @media screen and (min-width: "960px") {
    max-width: 250px;
    margin-left: auto !important;
  }
}

.carousel-image {
  @media screen and (min-width: "960px") {
    max-height: 370px;
  }
  @media screen and (max-width: "960px") {
    max-height: 320px;
    width: 100%;
  }
}

.carousel-container {
  // @media screen and (min-width: "960px") {
  height: 370px;
  // }
}

.custom-slider,
.subtitle-slider {
  width: 400px;

  @media screen and (max-width: 600px) {
    width: 200px;
  }
}

.custom-slider {
  height: 300px;
  @media screen and (max-width: 600px) {
    height: 250px;
  }
}
.img-review {
  width: 500px;
  height: 400px;

  @media screen and (max-width: 600px) {
    width: 300px;
    height: 250px;
  }
}

.btnLegend {
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}

.subtitle-slider {
  font-size: 18px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.slick-current > div:last-of-type {
  .custom-slider {
    margin: 10px 0px;
    height: 400px !important;

    @media screen and (max-width: 600px) {
      height: 300px !important;
    }
  }
}

.slick-track {
  display: flex !important;
  align-items: center !important;
}

.slick-list {
  padding: 0px !important;
}

.slick-list::after {
  content: "";
  width: 100%;
  height: 135%;
  position: absolute;
  top: -30px;
  left: 0;
  overflow: hidden;
  // box-shadow: inset 0 0 35px 35px white;
  pointer-events: none;

  @media screen and (max-width: 600px) {
    // box-shadow: inset 0 0 5px 5px white;
  }
}

.slick-next:before,
.slick-prev:before {
  color: #111 !important;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.file-manager__item-title {
  white-space: normal !important;
}

.review-comments-outsideModal {
  .like-comment {
    padding: 0px 55px;
  }

  .dislike-comment {
    padding: 0px 55px;
  }
}

.review-image-comments {
  .like-comment {
    margin-top: 10px;
    text-align: right;
    word-break: break-word;
  }
  .dislike-comment {
    margin-top: 10px;
    text-align: left;
    word-break: break-word;
  }
  @media (max-width: 768px) {
    .dislike-comment,
    .like-comment {
      text-align: center !important;
    }
  }
}

.mobile-brand {
  padding: 0px !important;

  @media screen and (min-width: 760px) {
    display: none !important;
  }
}

.kep-login-facebook {
  font-size: 12px !important;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  span {
    position: relative;
    display: inline-block;
    line-height: 1.14;
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: black;
    }
    &:after {
      right: 100%;
      margin-right: 15px;
    }
    &:before {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.centered-modal {
  @media screen and (min-width: "760px") {
    margin-left: 40% !important;
    margin-right: 10% !important;
  }
}

.hover-highlight {
  &:hover {
    box-shadow: 0px 0px 0px 1px green;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.login,
.checkout {
  .MuiStepper-root {
    background-color: transparent !important;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #17c671;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: var(--newbackground-color);
  }
}

.login {
  .mobile {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .first-access,
    .middle-split {
      display: none !important;
    }
    .already-a-user {
      width: 100% !important;
      max-width: 300px;
      margin: 0px !important;
    }

    .register-reference {
      display: block !important;
    }
  }
}

.checkout {
  .MuiStepper-root {
    padding-left: 0px;
    padding-right: 0px;
    .MuiStepLabel-label {
      font-family: margem;
      font-size: 28px;
      line-height: 0.7;
    }
  }

  .payment {
    & > div:first-child {
      padding-right: 6px;
    }
    & > div:nth-child(2) {
      padding-left: 6px;
    }
  }
}

@media screen and (max-width: "1024px") {
  .checkout {
    .payment {
      flex-direction: column;
      & > div {
        padding: 0px;
      }
    }
  }
}

.qr-code-modal {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0px;
}

.inputShowHide {
  .is-invalid,
  .is-valid {
    background-position: right 1.6rem center !important;
    padding-right: 2.9rem !important;
  }

  & > i {
    margin-left: -1.3rem;
  }

  & > input {
    padding-right: 2.9rem !important;
  }
}

button.transparent {
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  padding-left: 0px;
  padding-right: 0px;
  color: #5a6169;
}

body.no-scroll {
  overflow: hidden;
  * {
    overflow: hidden;
  }
}
