.addVoucher {
  text-transform: uppercase;
  margin: 0;
  font-size: 0.8rem;
  letter-spacing: 0.1rem;
  padding: 1px 1.5625rem;
  font-weight: 500;
  color: var(--newbackground-color);
  text-decoration: underline;
  text-align: center;
  margin-bottom: 1rem;
  background: none !important;
  border: none !important;
  margin-left: auto;
  margin-right: auto;
}

.voucherInput {
  max-width: 350px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
