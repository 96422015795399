.step2 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  & > div {
    max-width: 100%;
  }
  .card {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .step2 {
    flex-direction: row;
    & form {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
    .card:nth-child(1) {
      margin-right: 1rem;
    }
    .card:nth-child(2) {
      margin-right: 1rem;
    }
    & > div {
      padding-left: 12px;
      // padding-right: 12px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .step2 {
    .card:nth-child(1) {
      order: 2;
    }
    .card:nth-child(2) {
      order: 1;
    }
  }
}
