.cardWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem !important;
  margin-left: auto;
  margin-right: auto;
  max-width: 420px !important;
  :global {
    .card {
      width: 100% !important;
    }
  }
}

@media screen and (min-width: 1000px) {
  .isColLg {
    display: flex !important;

    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1200px;
    align-items: center;
    & > div:nth-child(2) {
      :global {
        .card {
          padding-bottom: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}
