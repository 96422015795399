.container_leroy {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.logo_leroy {
  max-width: 200px;
  margin: 22px auto;
}

.loader_leroy {
  margin: 0 auto;
}

.text_leroy {
  color: #555;
}
