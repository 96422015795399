// Main Stylesheet
@font-face {
  font-family: "margem";
  font-style: normal;
  font-weight: 400;
  src: url("https://s3.amazonaws.com/ladecora.platform/fonts/Margem-Medium.otf"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("https://s3.amazonaws.com/ladecora.platform/fonts/montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: "montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("https://s3.amazonaws.com/ladecora.platform/fonts/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "montserrat";
  font-style: bold;
  font-weight: 600;
  src: url("https://s3.amazonaws.com/ladecora.platform/fonts/montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("https://s3.amazonaws.com/ladecora.platform/fonts/montserrat/Montserrat-Thin.ttf");
}
body {
  font-family: "margem";
}
// Main SDP Styles
@import "../styles/shards-dashboards";

// Variables
@import "./variables";

// Plugin Styles
@import "./dropzone";
@import "./quill";
@import "./range-date-picker";
@import "./file-manager-list";
@import "./react-tagsinput";
@import "./react-big-calendar";
@import "./checkout";
@import "./steps";
@import "./project_chat_overrides";
@import "./questionary";
@import "./sidebar";
@import "./vouchers_modal";
