.enterprise {
  width: 100vw;
  height: 100vh;
  background-color: white;

  .flipImage img {
    transform: scaleX(-1);
  }
  .flipImage .div-image {
    -webkit-transform: scaleX(-1);
  }
  .enterprise-header {
    height: "3.7rem";
    padding-top: 1.5rem;
  }

  .left-sidebar-toggle {
    display: none;
  }

  .sidebar-done-icon {
    color: var(--newbackground-color);
  }

  .enterprise-content {
    width: calc(100vw - 15rem);
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 15rem;
    height: 100%;
  }
  .room-item {
    background-color: black;
    background-color: lightgrey;
    font-size: 1.1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: white;
    border-radius: 0.3rem;
    white-space: nowrap;
    // Material button hover scss
    &:hover {
      background-color: #e0e0e0;
      color: black;
      box-shadow: 0px 0px 0px 1px #e0e0e0;
    }
  }

  .blue-print-items {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    @media screen and (max-width: "760px") {
      justify-content: flex-start;
    }
  }

  .building-name {
    font-size: 1.5rem;
    color: black;
    font-weight: 800;
    // line-height: 1rem;
    // height: 3.7rem;
  }

  .main-panel {
    flex: 1;
    overflow-y: hidden;
    height: calc(100% - 7.2rem);
    width: calc(100vw - 15rem);
    display: block !important;
    position: relative;
  }

  .questionary {
    & > div {
      max-width: 50rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .left-sidebar-items {
    .left-sidebar-item {
      font-family: "montserrat";
      font-weight: 300;
      font-size: 1rem;
      color: black;
      margin-left: 1.5rem;
      border-radius: 2rem;
      &:hover {
        background-color: rgb(238, 238, 238);
      }
    }

    .left-sidebar-item-selected {
      border-color: transparent !important;
      box-shadow: var(--newbackground-color) 0px 0px 0px 2px !important;
    }
  }

  .enterprise-container {
    overflow-y: auto;
    height: calc(100% - 3.6rem);
  }

  .project-group {
    & > div {
      flex-direction: row;
    }
  }
  .project-group-images {
    width: 40%;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .project-group-prices {
    width: 50%;
    margin-left: 3rem;
    margin-right: 3rem;
    background-color: lightgrey;
    border-radius: 1rem;
    max-width: 500px;
    box-shadow: var(--newbackground-color) 0px 0px 0px 2px !important;
  }

  @media screen and (max-width: 1024px) {
    .project-group-images {
      width: 100%;
    }
    .project-group-prices {
      margin-left: 1rem;
      margin-right: 1rem;
      box-shadow: var(--newbackground-color) 0px 0px 0px 2px !important;
      margin-left: auto;
      margin-right: auto;

      width: calc(100% - 2rem);
    }
    .project-group {
      & > div {
        flex-direction: column;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .building-name {
      font-size: 1rem;
    }
    .enterprise-header {
      padding-top: 0.5rem;
    }
    .left-sidebar {
      left: -15rem;
    }
    .left-sidebar-toggle {
      display: inline-block;
    }
    .enterprise-content {
      left: 0rem;
      width: 100vw;
      .main-panel {
        width: 100vw;
      }
    }
    &.left-sidebar-opened {
      .left-sidebar {
        left: 0rem;
      }
      .enterprise-content {
        left: 15rem;
      }
    }
  }
}

.project-group-slider {
  .slick-slider {
    width: 100%;
  }
}
