// style for landing page

#mainRef {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
}

.vivadecora-class {
  font-family: sans-serif;
}

#mainRef button {
  text-transform: uppercase;
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  top: 2%;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
  font-weight: 700;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--newbackground-color);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

#info-top {
  width: 100%;
}

#info-top p {
  padding-left: 4.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0;
}

#info-top hr {
  margin-top: 0;
}

.content-after-nav {
  background-image: url("https://s3.amazonaws.com/ladecora.platform/images/renderLP.png");
  background-repeat: no-repeat, repeat;
  background-size: 100%;
  background-position: center;

  margin: 50px auto 0 auto;
  position: relative;
  float: right;
  width: 100%;
  max-width: 800px;
  max-height: 490px;
  padding-bottom: 40%;
}

.content-after-nav-vivadecora {
  background-image: url("https://s3.amazonaws.com/ladecora.platform/images/renderLPVD.webp");
  background-repeat: no-repeat, repeat;
  background-size: 100%;
  background-position: center;

  margin: 50px auto 0 auto;
  position: relative;
  float: right;
  width: 100%;
  max-width: 800px;
  max-height: 490px;
  padding-bottom: 40%;
}

.content-after-nav-child {
  display: inline-grid;
  background-color: #fff;
  width: 100%;
  max-width: 510px;
  position: absolute;
  float: left;
  top: 12px;
  margin-left: -50%;
  text-align: center;
  padding: 20px;
  padding-bottom: 25px;
}

.content-after-nav-child h3 {
  font-weight: bolder;
}

#howItWorksRef h4,
#faqLp h4,
#help-to-buy h3,
#howItWorksRef .card-title,
#howItWorksRef .card-header,
.content-after-nav-child h3,
#WhatYouGet h4,
#WhatYouGet h5,
#projects-done h4,
#price h4,
#price h5,
#price strong,
#testimony h4,
#vivadecora-inspirations h4,
#testimony h5 {
  color: var(--newbackground-color);
  font-weight: 800;
}

.content-after-nav-child p {
  margin: 20px 0;
  font-weight: 700;
}

.benefit-content {
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.benefit-content ul {
  padding: 0;
}

.content-after-nav-child p,
.benefit-content ul li {
  text-align: start;
}

.benefit-content ul li {
  list-style-type: none;
}

.content-after-nav-child button {
  width: 100%;
}

#howItWorksRef,
#price,
#help-to-buy {
  background-color: rgb(237, 236, 234);
}

#howItWorksRef {
  margin-top: 70px;
  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;
  text-align: center;
}

#WhatYouGet {
  max-width: 80%;
  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;
  width: 100%;
}

#howItWorksRef .row,
#WhatYouGet .row,
#price .row {
  justify-content: center;
  margin-top: 1.75rem;
}

#howItWorksRef .col-sm-12,
#howItWorksRef .col-md-4 {
  display: flex;
  justify-content: center;
  margin-bottom: 1.75rem;
}

#WhatYouGet .col-md-4,
#WhatYouGet .col-sm-12,
#WhatYouGet .col-md-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#howItWorksRef .card {
  align-items: center;
  padding: 1rem;
  max-width: 300px;
}

#howItWorksRef .card-header {
  padding: 0;
  font-size: x-large;
}

#howItWorksRef img,
#WhatYouGet img {
  width: 100%;
  max-width: 100px;
}

#howItWorksRef img {
  padding-bottom: 20%;
}

#WhatYouGet img {
  padding-bottom: 10%;
}

#howItWorksRef .card-body {
  padding: 0;
  min-height: 200px;
  padding-bottom: 5%;
}
#howItWorksRef .card-body p,
#price p {
  font-size: 16px;
}

#faqLp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  min-width: 100%;
  background-color: #fff;

  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

#faqLp .faq-row-wrapper {
  margin-bottom: 1.75rem;
}

#faqLp .row-title-text {
  padding-left: 10px;
}

#help-to-buy {
  display: flex;
  text-align: center !important;
  flex-direction: column;
  align-items: center;

  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;

  min-width: 100%;
}
#help-to-buy h3 {
  margin-top: 10px;
}

#projects-done .slick-slider {
  width: 90%;
}

#projects-done {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding-top: 45px;
  padding-left: 10%;
  padding-right: 5%;
  padding-bottom: 55px;
}

#projects-done h4 {
  position: relative;
  left: -4%;
}

#price .col-sm-6,
#price .col-md-2 {
  display: flex;
  justify-content: center;
  margin-bottom: 1.75rem;
}

#price {
  width: 100%;
  text-align: center;
  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;
}

#price ul {
  padding: 0;
  margin: 0;
}

#price li {
  list-style-type: none;
  width: 100%;
}

#price .card-body {
  width: 100%;
  height: 100%;
  padding: 1.2rem;
}

#price strong {
  font-weight: bold;
  font-size: 25px;
}
#vivadecora-inspirations {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 45px;
  width: 100%;
  text-align: center;
  .slick-prev {
    left: 5px !important;
    z-index: 1 !important;
    &:before {
      color: white !important;
    }
  }
  .slick-next {
    right: 25px !important;
    &:before {
      color: white !important;
    }
  }
}
#testimony {
  width: 90%;
  text-align: center;

  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 35px;
}

#testimony h4 {
  margin-bottom: 45px;
}

#testimony .slick-list::after {
  box-shadow: none !important;
}

#imgs-slider {
  width: 100%;
  display: flex !important;
  justify-content: center;
}

#vivadecora-inspirations {
  .custom-slider {
    height: 900px !important;
  }
}

#imgs-slider .custom-slider {
  width: 600px !important;
}

#rating {
  padding: 45px 95px;
  text-align: start;
}

#rating span {
  color: var(--newbackground-color) !important;
}

@media screen and (max-width: 1199px) {
  .content-after-nav,
  .content-after-nav-child {
    margin: auto;
  }
  .content-after-nav {
    margin-top: 50px;
  }
  .content-after-nav-child {
    margin-top: 20%;
    margin-left: 20%;
  }

  #howItWorksRef {
    margin-top: 350px;
  }
}

@media screen and (max-width: 768px) {
  #mainRef {
    font-size: 16px;
  }
  .info-top {
    text-align: center;
    padding-left: 0;
  }
  .hamburger {
    display: block;
  }
  .navigation-menu ul {
    z-index: 9999;
    display: none;
    position: absolute;
    top: 85px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    box-shadow: inset 0 4px 0 0 var(--newbackground-color);
  }
  .navigation-menu li a {
    width: 100%;
    padding: 10px 0;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li:hover {
    background-color: rgb(161, 160, 160);
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .content-after-nav-child {
    margin-top: 28%;
    margin-left: 0;
  }

  .content-after-nav {
    position: unset;
    width: 300px;
    height: 300px;
    float: unset;
  }

  .content-after-nav-child {
    margin-top: 170px;
    float: unset;
    position: unset;
    text-align: start;
  }

  .benefit-content {
    display: block;
  }

  .content-after-nav-child button {
    margin-top: 1.1rem;
  }

  #howItWorksRef {
    margin-top: 570px;
  }

  #price .card {
    width: 250px;
  }

  #imgs-slider {
    flex-direction: column;
    align-items: center;
  }

  #imgs-slider .custom-slider {
    width: 300px !important;
  }
  #testimony {
    padding: 20px;
  }

  #rating {
    padding: 45px 20px;
    text-align: start;
  }

  #faqLp {
    margin-bottom: 10.75rem;
  }
}
