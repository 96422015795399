.nameFields {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    margin-right: 1px;
  }
  & > div:nth-child(2) {
    margin-left: 1px;
  }
}
@media screen and (max-width: 1024px) {
  .nameFields {
    flex-direction: column;
  }
}
