.instructions-step {
  p {
    text-align: right;
    padding-left: 32px;
    padding-right: 12px;
    @media screen and (max-width: 990px) {
      text-align: left;
    }
  }

  @media screen and (max-width: 990px) {
    .button-group {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      button {
        margin-right: 0px;
        margin-top: 8px;
      }
    }
  }

  @media screen and (min-width: 990px) {
    .button-group {
      float: right;
    }
  }
}

.MuiTypography-root.MuiStepLabel-label.MuiStepLabel-alternativeLabel.MuiStepLabel-completed.MuiTypography-body2.MuiTypography-displayBlock {
  min-width: 60px !important;
}

.projectNameRow {
  @media screen and (max-width: 990px) {
    input {
      margin-bottom: 20px;
    }

    label {
      margin-top: 10px;
    }

    button {
      float: right;
    }
  }
}

.rsw_2Y {
  .rsw_2f:not(.rsw_3G) {
    display: none !important;
  }
}

.multipleimage-image {
  width: 100%;
  height: 278px;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-size: contain;
}

.multipleimage-image {
  //Aspect Ratio
  padding-top: 82.64%;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-size: contain;
  width: 100%;
  height: 0;
}

.project-highlight {
  box-shadow: #5a616982 0px 0px 12px 7px, #5a616982 0px 1px 7px,
    #5a616982 0px 1px 7px, #5a616982 0px 1px 7px;
}
