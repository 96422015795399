.floatingButton {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.floatingContainer {
  position: fixed;
  right: 57px;
  bottom: 5px;
  border: none;
  background-color: var(--newbackground-color);
  padding: 0.4rem;
  border-radius: 1.2rem;
  box-shadow: 0px 0px 8px black;
  p {
    padding: 0px;
    margin: 0px;
    font-size: 0.9rem;
    color: white;
  }
}

@media (min-width: 414px) {
  .floatingContainer {
    right: 70px;
    p {
      font-size: 1rem;
    }
  }

  .floatingButton {
    width: 70px;
    height: 70px;
  }
}
