.wizard-footer {
  width: 83.33%;
}

@media (max-width: 992px) {
  .wizard-footer {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .wizard-footer {
    width: 100%;
  }

  .wizard {
    padding: 0.8rem !important;
  }
}
