.answer-selected {
  border-color: transparent !important;
  box-shadow: var(--newbackground-color) 0px 0px 0px 2px !important;
  background-color: rgb(247, 247, 247) !important;
}

.answer {
  border: 1px solid lightgrey;
  font-size: 1.2rem;
  padding: 1rem 2rem 1rem 2rem;
  margin-bottom: 2rem;
  color: black;
  text-align: center;
  border-radius: 0.4rem;
  &:hover {
    border-color: transparent !important;
    box-shadow: var(--newbackground-color) 0px 0px 0px 2px !important;
  }
}

.picture-item {
  border: 1px solid lightgrey;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: black;
  border-radius: 0.4rem;
  .picture-item-bottom-text {
    font-family: "montserrat";
    font-size: 1rem;
    padding: 0rem 2rem 0rem 2rem;
  }
}
.picture-item--selected {
  border-color: transparent !important;
  box-shadow: var(--newbackground-color) 0px 0px 0px 3px !important;
}

.small-container {
  height: 100%;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
}

.question-title {
  font-family: "montserrat";
  font-size: 1.6rem;
  color: black;
  font-weight: 400;
  text-align: center;
}
.flip-image .div-image{
  -webkit-transform: scaleX(-1)
}

.questionary {
  label {
    font-family: "montserrat";
    font-size: 1.6rem;
    color: black;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.action-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 3px solid #f7f7f7 !important;
  height: 4.5rem;
  background-color: #fff;
}

.answer-container {
  width: 25rem;
}

@media (max-width: 768px) {
  .answer-container {
    width: 100%;
  }
}
