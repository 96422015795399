.page {
  min-height: calc(100vh - 7.5rem);
}

.card {
  border: 1px solid black;
  border-bottom: 3px solid;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 12px;
  border-radius: 0.7rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 100%;
  border-color: transparent;
  height: 520px;
}

.slide {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  min-width: 285px;
}

.missingInfo {
  border-color: red !important;
  .warnSign {
    background-color: red;
  }
}
.missingApproval {
  border-color: green;
  .warnSign {
    background-color: green;
  }
}

.container {
  :global {
    .slick-prev {
      left: 5px !important;
      z-index: 1 !important;
    }
    .slick-next {
      right: 25px !important;
    }
    .slick-dots {
      bottom: -60px;
    }
  }
  align-items: stretch;
  justify-content: center;
  width: 100%;
  padding: 2rem 20px 2rem 20px;

  & .slick-track {
    display: flex;
    align-items: stretch !important;
    flex-direction: row;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 2rem 0px 2rem 0px !important;
  }
}

.slideGroup {
  display: flex !important;
  align-items: stretch;
  flex-direction: row;
  overflow: hidden;
}

.divider {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.statusHeader {
  max-width: 250px;
  text-align: center;
  margin-top: 0.5rem;
  // margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1;
  // font-family: "exo2";
}

.statusText {
  max-width: 250px;
  text-align: center;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  line-height: 1;
  font-family: "exo2";
  flex: 1;
}

.dueDateText {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  //   margin-top: 10px;
}

.actionButton {
  //   width: 100%;
  margin: 4px;
  margin-top: 10px;
  padding: 5px;
  margin-top: auto;
}

.title {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  max-width: 250px;
  line-height: 1.1;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-word;
}

.cardHeader {
  //   margin-bottom: auto;
}

.cardBody {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.newProject {
  border: 0.2rem dashed darkgray;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  flex-direction: column;
  color: #5a6169;
}

.warnSign {
  position: absolute;
  right: -8px;
  top: -8px;
  z-index: 100;
  padding: 0.1rem 0.4rem 0.1rem 0.4rem;
  //   background-color: red;
  color: white;
  border-radius: 50%;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  div > div {
    border-radius: 6px;
  }
}
