@media screen and (min-width: 1024px) {
  .vouchers-modal {
    center {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      & > div:nth-child(2) {
        margin-left: 2rem;
      }
      button {
        width: 13rem;
      }
    }
  }

  .voucher-button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}

.vouchers-modal {
  overflow-y: auto !important;
  overflow-x: hidden;
  max-height: 75vh;
  height: 100%;
  .voucher-text {
    border: 1px solid black;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 1.6rem;
    width: auto;
    display: inline;
    border-radius: 0.2rem;
  }
}
