/* YourComponent.module.scss */
.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-height: 400px;
  position: relative;
  height: 100%;
  padding-top: 38%;
  max-height: 460px;

  .innerContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    left: 0;

    .left {
      position: relative;
      width: 100%;
      height: 100%;
      margin-right: 0.5rem;
      background-image: url("https://s3.amazonaws.com/ladecora.platform/images/d8de2f0f-cdf4-4eae-9f17-4b9e7984ac8e.png");
      background-size: cover;
      background-position: 37% 0%;
      background-repeat: no-repeat;
    }

    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .image1 {
        width: 100%;
        height: 120%;
        background-image: url("https://s3.amazonaws.com/ladecora.platform/images/image_1_pdf.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 0.5rem;
        position: relative;
      }

      .image2 {
        width: 100%;
        height: 100%;
        background-image: url("https://s3.amazonaws.com/ladecora.platform/images/mobi_pdf.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
      }
    }

    .right {
      width: 100%;
      height: 100%;
      margin-left: 0.5rem;
      background-image: url("https://s3.amazonaws.com/ladecora.platform/images/6ae4ea65-04a2-465a-91ae-3c740064116e.png");
      background-size: cover;
      background-position: 37% 0%;
      background-repeat: no-repeat;
      position: relative;
    }

    .textBottom {
      position: absolute;
      left: 0;
      width: 100%;
      height: 80px;
      bottom: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      font-size: 1.5rem;
      color: white;
      background-color: grey;
      opacity: 0.9;
      text-align: center;
      flex-direction: column;
      font-size: 1.5vw;
    }
  }
}

@media (min-width: 1200px) {
  .textBottom {
    font-size: 1.2rem !important;
  }
  .container {
    padding-top: 460px;
  }
}
